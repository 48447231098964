// Crypto
import crypto from "crypto-js"
import { async } from "q"

const config = {
  projectId: process.env.GATSBY_projectId,
  appId: process.env.GATSBY_appId,
  databaseURL: process.env.GATSBY_databaseURL,
  storageBucket: process.env.GATSBY_storageBucket,
  locationId: process.env.GATSBY_locationId,
  apiKey: process.env.GATSBY_apiKey,
  authDomain: process.env.GATSBY_authDomain,
  messagingSenderId: process.env.GATSBY_messagingSenderId,
  measurementId: process.env.GATSBY_measurementId,
}

let firebaseInstance

export const getFirebase = firebase => {
  if (firebaseInstance) {
    return firebaseInstance
  }

  firebase.initializeApp(config)
  firebaseInstance = firebase

  console.log("FIREBASE INITALIZED", config)

  return firebase
}

export const generateAuthCode = userId => {
  const lazyApp = import("firebase/app")
  const lazyFunctions = import("firebase/functions")

  console.log("GENERATING AUTH CODE")

  Promise.all([lazyApp, lazyFunctions]).then(async ([firebase]) => {
    const functions = getFirebase(firebase).functions()

    // Encrypt UserID + API Key
    let encryptedToken = crypto.AES.encrypt(userId, config.apiKey).toString()
    var generateAuthCode = functions.httpsCallable("generateAuthCode")

    try {
      const result = await generateAuthCode({
        token: encryptedToken,
        source: "web",
      })
      console.log("User auth code generated", result)
    } catch (error) {
      console.log("Error generating user auth code", error)
    }
  })
}
