import * as React from "react"

// Style
import styled from "styled-components"

// Loadable
import Loadable from "react-loadable"
import Loading from "../components/fs_loading"

export const LLayout = Loadable({
  loader: () => import("antd").then(module => module.Layout),
  loading: Loading,
})

export const LButton = Loadable({
  loader: () => import("antd").then(module => module.Button),
  loading: Loading,
})

export const LRow = Loadable({
  loader: () => import("antd").then(module => module.Row),
  loading: Loading,
})

export const LCol = Loadable({
  loader: () => import("antd").then(module => module.Col),
  loading: Loading,
})

export const LSpin = Loadable({
  loader: () => import("antd").then(module => module.Spin),
  loading: Loading,
})

export const LTitle = Loadable({
  loader: () => import("antd").then(module => module.Typography.Title),
  loading: Loading,
})

export const LText = Loadable({
  loader: () => import("antd").then(module => module.Typography.Text),
  loading: Loading,
})

export const LContent = Loadable({
  loader: () => import("antd").then(module => module.Layout.Content),
  loading: Loading,
})

export const LNavEmpty = Loadable({
  loader: () =>
    import("./nav/fs_nav_empty").then(module => module.FEmptyNavHeader),
  loading: Loading,
})

export const LNavEmptyLeft = Loadable({
  loader: () =>
    import("./nav/fs_nav_empty").then(module => module.FEmptyNavHeaderLeft),
  loading: Loading,
})

export const LNavEmptyFloating = Loadable({
  loader: () =>
    import("./nav/fs_nav_empty").then(module => module.FEmptyNavFloatingHeader),
  loading: Loading,
})

export const LFooterLove = Loadable({
  loader: () => import("./footer/fs_footer_love"),
  loading: Loading,
})

export const LFPinCode = Loadable({
  loader: () => import("./fs_six_digit_pincode_input"),
  loading: Loading,
})

export const LFImageUpload = Loadable({
  loader: () => import("./fs_content_uploader"),
  loading: Loading,
})

export const LMasonGallery = Loadable({
  loader: () => import("react-photo-gallery"),
  loading: Loading,
})

export const FFullPage = styled.div`
  &&& {
    display: table;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #fafafa;
  }
`
export const FFullPageContent = styled.div`
  &&& {
    display: table-cell;
    vertical-align: middle;
  }
`

export const FContent = styled.div`
  &&& {
    display: table-cell;
    vertical-align: middle;
  }
`
